import React from "react";
import Layout from "../layouts/Main";
import { SEO, Title, Button } from "components";
import travolta from "../images/7VE.gif";
import Lottie from "react-lottie";
import notFoundAnimation from "../images/json/404.json";
import { StaticImage } from "gatsby-plugin-image";

const FIRST_ROLE = ["Account Manager", "Lead Designer", "CFO"];
const SECOND_ROLE = ["Developers", "Backend Developers", "Marketing"];

function NotFoundPage() {
  const index = Math.round(Math.random() * 2);

  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="mt-36 px-8 md:px-36 flex flex-col md:flex-row">
        <div className="flex flex-col">
          <Title>404 </Title>
          <h2 className=" text-xl inline-block mt-2 mb-4 md:w-1/2">
            Almeno hai trovato un'accurata rappresentazione di un{" "}
            {FIRST_ROLE[index]} in riunione con il team di {SECOND_ROLE[index]}.
          </h2>
          <div className="">
            <Button>Torna all'homepage</Button>
          </div>
        </div>

        <div className="mt-12 h-auto w-auto">
          {/* <Lottie
            options={notificationWhiteOptions}
            speed={1}
            isStopped={false}
            isPaused={false}
          /> */}
          <img src={travolta} alt="Travolta not found" />
        </div>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
